import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BlocksRenderer from "../components/blocks-renderer"
import Headings from "../components/headings"

const PrivacyPolicyPage = () => {
  const { strapiPrivacyPolicy } = useStaticQuery(graphql`
    query {
      strapiPrivacyPolicy {
        id
        description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  `)
  const { description } = strapiPrivacyPolicy

  const seo = {
    metaTitle: 'Privacy Policy',
    metaDescription: 'Privacy Policy',
  }

  return (
    <Layout>
      <Seo seo={seo} />
      <div className="markdown"
        dangerouslySetInnerHTML={{
          __html: description.data.childMarkdownRemark.html
        }}
      />
    </Layout>
  )
}

export default PrivacyPolicyPage
